// Doctor side Video Call
import React, { useState, useRef, useEffect } from "react";
import Peer from "simple-peer";
import { toastInfo } from "../../../Redux/Reducers/admin/adminPanelSlice";

const VideoCall = ({ me, consInfo, socket, onlineUsers }) => {
  // console.log(consInfo,"consInfo");
  // console.log(socket,"SHOCKET"
  //   ,consInfo,"CONSiNFO"
  //   ,me,"mEeee");
  //   console.log(onlineUsers,"onlineuser");
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState("James Pearlman");
  const [call, setCall] = useState({});
  const [userId, setUserId] = useState();
  const [userOff, setUserOff] = useState(false);
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  useEffect(() => {
    if (navigator) {
      navigator?.mediaDevices
        ?.getUserMedia({ video: true, audio: true })
        .then((currentStream) => {
          setStream(currentStream);
          myVideo.current.srcObject = currentStream;  
        });
    }
    socket.on("call_User", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    socket.on("user-left", () => {
      toastInfo("User left the Call");
      setCallEnded(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      //  const socketId = onlineUsers?.find(
      //     (item) => item.userId == consInfo?.user_id?._id
      //   )?.socketId;
      //   console.log(socketId,'socketId');
      //   setUserId(socketId)
    });

    return () => {
      window.location.reload();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      const isUser = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      );
      if (isUser) {
        // setUserOff(true)
        // const socketId = onlineUsers?.find(
        //   (item) => item.userId == consInfo?.user_id?._id
        // )?.socketId;
        // console.log(socketId,'socketId');
        // setUserId(socketId)
        // connectionRef.current.onDisconnect();
      }
    }
  }, [onlineUsers]);
  useEffect(() => {
    if (consInfo && onlineUsers) {
      const socketId = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      )?.socketId;
      if (socketId) {
        setUserId(socketId);
      }
    }
  }, [consInfo, onlineUsers]);

  // const answerCall = () => {
  //   setCallAccepted(true);

  //   const peer = new Peer({ initiator: false, trickle: false, stream });

  //   peer.on("signal", (data) => {
  //     socket.emit("answerCall", { signal: data, to: call.from });
  //   });

  //   peer.on("stream", (currentStream) => {
  //     userVideo.current.srcObject = currentStream;
  //   });

  //   peer.signal(call.signal);

  //   connectionRef.current = peer;
  // };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    socket.emit("doctor-disconnected", { to: userId });
    setCallEnded(true);
    setCallAccepted(false);
    connectionRef.current.destroy();
    window.location.reload();
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          onClick={leaveCall}
          className={
            callAccepted && !callEnded
              ? "btn btn-primary white-btn small_white_btn"
              : "btn btn-primary white-btn small_white_btn visibility-hidden"
          }
        >
          Leave Call
        </button>
      </div>
      <div className="card mb-4 video_call_section">
        <div className="pb-0">
          <div className="consulatation_card">
            {callAccepted && !callEnded ? (
              <video
                playsInline
                width="626"
                height="323"
                ref={userVideo}
                autoPlay
                className="full-video"
              />
            ) : (
              <img
                src={require("../../../assets/images/lg_video_frame.png")}
                alt="live_chat"
                className="img-fluid large_img"
              />
            )}
            <img
              src={require("../../../assets/images/small_video_frame.png")}
              alt="live_chat"
              className="img-fluid video_call_frame"
            />
            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
              className="doctor-video"
            />
            {userId && !callAccepted && (
              <div className="start_call">
                <button
                  onClick={() => userId && callUser(userId)}
                  className="cre_new"
                >
                  Start Call
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCall;
